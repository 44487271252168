<template lang="pug">
.ex(ref="target")
  .templates-search__main-row.base-input(ref="reference" @click.stop="toggle")
    .tags
      span(v-if="!templatesLabels.length") Выберите шаблон
      template(v-else)
        ui-tag(v-for="tag of templatesLabels" :key="tag" :name="tag")

    slot
    ui-icon.toggle-icon(
      clickable
      :size="14"
      :class="visible && '_reverse'"
      :icon="UiIconNames.Chevron_ArrowDown"
      @click.stop="toggle"
    )

  .dropdown(v-if="visible" ref="floating" :style="{...floatingStyles, width: popperWidth }")
    .dropdown-content
      .header
        .header-label._main Мои сохраненные шаблоны
        .header-label._secondary(v-if="showLots") непросмотренные тендеры
      form-input(clearable v-model="filterInput" placeholder="Введите название шаблона")
      el-tree.content(
        ref="treeRef"
        check-on-click-node
        show-checkbox
        default-expand-all
        node-key="key"
        :check-strictly="analyticMode"
        :data="data"
        :props="{ children: 'templates', label: 'title' }"
        :filter-node-method="filterNode"
        :expand-on-click-node="false"
        :default-checked-keys="selectedTemplatesIds"
        @check="handleCheckNode"
      )
        template(#default="{ data }")
          .tree-item.group(v-if="data.isGroup")
            .meta-name {{ data.meta.title }}
            .group-actions(v-if="data.meta.id !== -1 && showLots && !roleReadOnly")
              icon-button(
                :size="16"
                :icon="UiIconNames.Icon_Edit"
                tooltip-title="Переименовать группу шаблонов"
                @click.stop="onRenameGroupClick(data.meta)"
              )
              icon-button(
                :size="16"
                :icon="UiIconNames.Icon_Delete"
                tooltip-title="Удалить группу шаблонов"
                @click.stop="onDeleteGroupClick(data.meta, resetAfterDelete)"
              )
          .tree-item(v-else)
            .meta-name
              span {{ data.meta.title }}
              span.secondary(v-if="data.meta.id !== -2 && data.meta.responsible") Ответственный: {{ data.meta.responsible?.fio }}, {{ data.meta.responsible?.emailForNotification }}
            .expert-template(v-if="data.meta.type === EXPERT_TEMPLATE")
              ui-tag Экспертный шаблон
            .lot-count(v-if="showLots && !isTmManager && data.meta.hasOwnProperty('unseen')" :class="data.meta.unseen <= 0 && '_null'")
              | {{ data.meta.unseen > 0 ? "+" : "" }} {{ data.meta.unseen >= 0 ? data.meta.unseen?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 }}
      .actions(v-if="!analyticMode")
        ui-button(type="secondary" @click.stop="getCheckedTemplateIds") Применить
        ui-button(type="secondary" @click.stop="clearSelect") Очистить выбор
        ui-button.delete-button(v-if="showLots && showDeleteButton && !roleReadOnly" type="secondary" @click.stop="deleteSelected") Удалить
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useToggle } from "@/use/other/useToggle";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useFloating, shift, autoUpdate } from '@floating-ui/vue';
import { useVModel, useElementSize, onClickOutside } from "@vueuse/core";
import { useSearchGlobals } from "~/use/search/useSearchGlobals";

import useTemplatesStore from "@/stores/templates/useTemplatesStore";

import type Node from "element-plus/es/components/tree/src/model/node";
import type { ElTree } from "element-plus";
import type { GroupI, TemplateI } from "@/stores/templates/SearchTemplatesInterface";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiButton from "@/components/ui/button/UiButton.vue";
import FormInput from "@/components/ui/form/input/FormInput.vue";
import UiTag from "@/components/ui/tag/UiTag.vue";
import IconButton from "~/components/ui/button/IconButton.vue";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import { EXPERT_TEMPLATE } from "@/const/templates";
import isEqual from "lodash/isEqual";
import type { TemplateManagerI } from "~/stores/auth/UserInterface";
import useUsersStore from "~/stores/users/useUsersStore";

interface TreeElement {
  key: number | string;
  meta: {
    id: number;
    title: string;
    type?: number;
    unseen?: number;
    groupId?: number | null;
    responsible?: TemplateManagerI | null;
  };
  disabled?: boolean;
  isGroup?: boolean;
  templates?: TreeElement[];
}

interface ExtendedTemplatesListI {
  group: GroupI,
  templates: TemplateI[],
}

export default defineComponent({
  name: "TemplateSelector",
  components: {
    IconButton,
    UiTag,
    FormInput,
    UiIcon,
    UiButton,
  },
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => ([]),
    },
    analyticMode: {
      type: Boolean,
      default: false,
    },
    showLots: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "accept",
    "delete",
    "check:template",
    "clear:select",
    "update:modelValue",
  ],
  setup(props, context) {

    // мне жаль того кто будет в этом разбираться, я рефакторила это раз 20, каждый раз все лучше и лучше, но все равно тут сам черт ногу сломит
    // удачи!

    const filterInput = ref('');
    const treeRef = ref<InstanceType<typeof ElTree>>();

    const reference = ref(null);
    const floating = ref(null);

    const target = ref(null)
    const { visible, toggle, hide: hideSelector } = useToggle();

    const { isTmManager, enableFastSearch, roleReadOnly } = useUserAccess();

    const {
      onDeleteGroupClick,
      onRenameGroupClick,
    } = useSearchGlobals();

    const templatesStore = useTemplatesStore();
    const selectedTemplatesIds = useVModel(props, 'modelValue', context.emit);

    const showDeleteButton = computed(() => {
      const nodes = treeRef.value?.getCheckedNodes(true) || []
      return nodes.filter(e => e.meta.type !== 2 && e.meta.id !== -2).length
    })

    const templatesMeta = computed<TemplateI[]>(() => templatesStore.templatesList.filter((t: TemplateI) => selectedTemplatesIds.value.includes(t.id)))

    const templatesLabels = computed(() => {
      if (
        selectedTemplatesIds.value.length === [-2, ...templatesStore.templatesList.map(e => e.id)].length
        && selectedTemplatesIds.value.includes(-2)
        && templatesStore.templatesList.every(e => selectedTemplatesIds.value.includes(e.id) )
      ) return ['Все шаблоны']

      const num = props.showLots ? 1 : 5;
      const a = [
        ...(selectedTemplatesIds.value?.includes(-2) ? ['Закупки без шаблона'] : []),
        ...templatesMeta.value.map((option:any) => option.title)
      ]
      return a.length > num ? [...a.slice(0, num), `+${ a.length - num }`] : a
    })

    onClickOutside(floating, () => {
      hideSelector()
    }, { ignore: [target] })

    const { floatingStyles }
      = useFloating(reference, floating, { placement: 'bottom-start', middleware: [shift()], whileElementsMounted: autoUpdate });

    const { width } = useElementSize(reference, 0, { box: 'border-box' });

    const popperWidth = computed(() => width.value ? `${width.value}px` : '640px');

    function resetChecked() {
      if (treeRef.value) treeRef.value.setCheckedNodes([], false);
    }

    function setCheckedNodes(data: TreeElement) {
      if (treeRef.value) treeRef.value.setCheckedNodes([data] as unknown as Node[], false);
    }

    watch(filterInput, (val: string) => {
      if (treeRef.value) treeRef.value.filter(val);
    });

    watch(selectedTemplatesIds, () => {
      treeRef.value?.setCheckedKeys(selectedTemplatesIds.value, false);
    });

    /** массив всех групп вида { meta: данные по группе, templates: список ее шаблонов }
      * шаблоны без группы хранятся в группе с нулевым айдишником для удобства (чтобы все выглядело одинаково)
      */
    const search1TemplatesGetter = computed<ExtendedTemplatesListI[]>(() =>
      templatesStore.groupsList.map(group => {
        return {
          group: { ...group },
          templates: templatesStore.templatesList.filter(t => t.groupId === group.id)
        }
      })
    )

    function getTemplateMeta(template: TemplateI): TreeElement {
      return {
        key: template.id,
        meta: {
          ...template,
          responsible: template.responsible ? {
            ...template.responsible,
            fio: useUsersStore().usersList.find(e => e.id === template.responsible?.id)?.fio || template.responsible?.fio
          } : null,
        },
        isGroup: false,
        disabled: props.analyticMode && template.type === EXPERT_TEMPLATE,
      }
    }

    function resetAfterDelete() {
      const newSelectedValue = selectedTemplatesIds.value.filter(e => templatesStore.templatesList.map(e => e.id).includes(e) || e === -2)
      applyTemplatesIds(newSelectedValue)
    }

    /** Дерево групп и шаблонов для селектора */
    const data = computed<TreeElement[]>(() => [
      {
        key: 'group-key-all',
        meta: { id: -1, title: "Все шаблоны" },
        isGroup: true,
        disabled: props.analyticMode,
        templates: [
          {
            key: -2,
            meta: { id: -2, title: "Закупки без шаблона" },
            isGroup: false,
            disabled: props.analyticMode,
          },
          ...(search1TemplatesGetter.value.find((g: ExtendedTemplatesListI) => g.group.id === 0)?.templates?.map((template: TemplateI) => getTemplateMeta(template)) || []),
          ...(search1TemplatesGetter.value.filter((g: ExtendedTemplatesListI) => g.group.id !== 0 && g.templates.length > 0)?.map((group: ExtendedTemplatesListI) => ({
            key: 'group-key-' + group.group.id,
            meta: { ...group.group },
            isGroup: true,
            disabled: props.analyticMode && !!group.templates,
            templates:
              group.templates &&
              group.templates.map((template: TemplateI) => getTemplateMeta(template)),
          })) || []),
        ],
      },
    ]);

    function filterNode(value: string, data: TreeElement) {
      if (!value) return true;
      const regexp = new RegExp(value, 'i');
      return regexp.test(data.meta.title)
        || regexp.test(data.meta.responsible?.fio || '')
        || regexp.test(data.meta.responsible?.emailForNotification || '');
    }

    function handleCheckNode(data: TreeElement) {
      if (props.analyticMode) {
        resetChecked();
        setCheckedNodes(data);
        hideSelector();
        context.emit('check:template', data.meta.id)
      }
    }

    function clearSelect() {
      if (treeRef.value) treeRef.value.setCheckedKeys([], false);
      context.emit('clear:select')
    }

    function getCheckedTemplateIds() {
      // айдишники выбранных шаблонов
      const ids = treeRef.value?.getCheckedKeys(true) || []
      applyTemplatesIds(ids)
    }

    function applyTemplatesIds(ids: number[]) {

      if (!ids.length) {
        context.emit('clear:select')
        hideSelector()
        return
      }

      context.emit('accept', { templates: ids })
      hideSelector()
    }

    function deleteSelected() {
      context.emit('delete', treeRef.value?.getCheckedKeys(true) || [])
    }

    return {
      toggle,
      target,
      popperWidth,
      visible,
      reference,
      floating,
      floatingStyles,
      data,                     // данные для дерева
      templatesLabels,          // список названий выбранных шаблонов (неполный)
      filterInput,              // фильтрация по дереву
      treeRef,
      selectedTemplatesIds,
      deleteSelected,
      isTmManager,
      roleReadOnly,
      enableFastSearch,
      showDeleteButton,
      resetAfterDelete,
      onDeleteGroupClick,
      onRenameGroupClick,
      hideSelector,
      handleCheckNode,
      filterNode,
      getCheckedTemplateIds,
      clearSelect,
      EXPERT_TEMPLATE,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/other/other";
@import "@/assets/styles/elements/baseInput";

.ex {
  display: contents;
}

.templates-search__main-row {
  width: 100%;
  background-color: white;
  display: flex;
  flex-flow: row;
  gap: 8px;
  align-items: center;
  padding: 6px 16px !important;
  user-select: none;
  height: 44px;

  &._small {
    width: 420px;
  }

  .tags {
    width: 100%;
    display: flex;
    flex-flow: row;
    gap: 8px;
    user-select: none;
  }

  :deep(.ui-tag) {
    display: block;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .toggle-icon {
    margin: 0 4px;
    color: #a8abb2;
    transition: transform 0.3s;

    &._reverse {
      transform: rotateZ(-180deg);
    }
  }
}

.meta-name {
  display: flex;
  flex-flow: column;
  gap: 2px;

  .secondary {
    font-size: 12px;
    color: var(--secondary-text-color);
  }
}

.dropdown {
  z-index: 10;
  min-width: 720px;
}

.dropdown-content {
  display: flex;
  flex-flow: column;
  gap: 8px;

  margin-top: 8px;
  padding: 8px 16px;

  box-shadow: var(--dialog-border-shadow);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;

  .header {
    display: flex;
    gap: 8px;
    align-items: baseline;
    padding: 4px 0;

    .header-label {
      font-size: 13px;
      line-height: 24px;

      &._main {
        font-size: 14px;
        font-weight: bold;
        color: var(--main-color-blue);
      }

      &._secondary {
        margin-left: auto;
      }
    }
  }

  .content {
    max-height: 288px;
    overflow: auto;

    :deep(.el-tree-node__content) {
      height: auto;
      min-height: 36px;
    }

    :deep(.el-tree-node__label) {
      width: 100%;
    }

    .tree-item {
      display: flex;
      gap: 8px;
      font-size: 14px;
      line-height: 16px;
      color: var(--main-text-color);
      align-items: center;
      width: 100%;

      .meta-name {
        padding: 4px 0;
        white-space: break-spaces;
        width: 100%;
      }

      &.group {
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
      }
    }

    .group-actions {
      display: flex;
      flex-flow: row;
      padding-right: 40px;
      width: 80px;
    }
  }

  .actions {
    display: flex;
    flex-flow: row;
    gap: 8px;
  }

  .delete-button {
    color: var(--main-red-color);
    background-color: #ffecf0;
    border-color: var(--main-red-color);
    margin-left: auto;
    margin-right: 40px;

    &:hover {
      color: white;
      border-color: var(--main-red-color) !important;
      background-color: var(--main-red-color) !important;
    }
  }
}
</style>
