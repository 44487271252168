import { ref } from "vue";
import type { GroupI } from "~/stores/templates/SearchTemplatesInterface";

const group = ref();
let resolveFunction : Function|undefined = undefined;

const modifyGroupMode = ref('create');
const showDeleteGroupDialog = ref(false);
const showModifyGroupDialog = ref(false);

export function useSearchGlobals() {

  function onResolve(data?: any) {
    if (resolveFunction) {
      resolveFunction(data);
      resolveFunction = undefined;
    }
  }

  function onDeleteGroupClick(g: GroupI, resolve?: Function) {
    group.value = g;
    if (resolve) resolveFunction = resolve;
    showDeleteGroupDialog.value = true;
  }

  function onRenameGroupClick(g: GroupI, resolve?: Function) {
    group.value = g;
    modifyGroupMode.value = 'rename'

    if (resolve) resolveFunction = resolve;
    showModifyGroupDialog.value = true;
  }

  function onCreateGroupClick(resolve?: Function) {
    group.value = undefined;
    modifyGroupMode.value = 'create'

    if (resolve) resolveFunction = resolve;
    showModifyGroupDialog.value = true;
  }

  return {
    group,
    modifyGroupMode,
    showDeleteGroupDialog,
    showModifyGroupDialog,
    onResolve,
    onDeleteGroupClick,
    onRenameGroupClick,
    onCreateGroupClick,
  }
}
